import React, { useState } from "react";
import './tutorial.scss';
import { Button } from "@mui/material";

function Tutorial(props: any) {
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  }

  return (
    <div className={'tutorial-container'}>

      {currentPage === 0 &&
        <div>
          <div className={'tutorial-header'}>Welcome to RewardRush!</div>
          <div className={'tutorial-txt'} style={{marginBottom: '12px'}}>
            You have been invited to an exclusive gaming experience reserved for our dedicated Rewarded Play users.
            This game combines strategy, social interactions, and a little bit of luck to compete.
            A prize pool of $50,000 will be shared amongst the players who collect all the souvenirs the quickest!
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img width={200} src={'./generic_rr.svg'} alt={'Generic Travel'} />
          </div>
          <div className={'tutorial-btn-container-single'}>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }

      {currentPage === 1 &&
        <div>
          <div className={'tutorial-header'}>How to win!</div>
          <div className={'tutorial-txt'} style={{marginBottom: '24px'}}>
            Your goal is to go on a European Vacation and obtain 30 unique souvenirs.
            You collect them by obtaining Travel Vouchers that allow you to visit locations in several countries in Europe.
            You take that voucher and redeem at the booking page with one of the Agents.
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img width={200} src={'./generic_rr.svg'} alt={'Generic Travel'} />
          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }

      {currentPage === 2 &&
        <div>
          <div className={'tutorial-header'}>Booking Tickets</div>
          <div className={'tutorial-txt'} style={{marginBottom: '8px'}}>
            Each agent can award you a Green Ticket, Blue Ticket, or Gold Ticket.
            Agents have different chances of each ticket type and some Agents may have a risk of losing your Ticket so choose wisely.
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={'./tutorial_reliable_assistant.png'} alt={'Reliable Assistant'} width={145} style={{marginRight: '8px'}}/>
            <img src={'./tutorial_travel_agent.png'} alt={'Travel Agent'} width={145} />
          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }

      {currentPage === 3 &&
        <div>
          <div className={'tutorial-header'}>Locked Agents</div>
          <div className={'tutorial-txt'} style={{marginBottom: '8px'}}>
            Some Agents are locked until you earn certain Achievements.  You can see available and earned Achievements in your Profile Page
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={'./tutorial_uncle_vic.png'} alt={'Uncle Vic'} width={145} style={{marginRight: '8px'}}/>
            <img src={'./tutorial_shady_assistant.png'} alt={'Shady Assistant'} width={145} />
          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }

      {currentPage === 4 &&
        <div>
          <div className={'tutorial-header'}>Tickets</div>
          <div className={'tutorial-txt'} style={{marginBottom: '20px'}}>
            Agents turn your vouchers into tickets.  There are three types of tickets:
          </div>
          <div>

            <div className={'tutorial-tickets-row'}>
              <div>
                <img src={'./green_ticket_small.svg'} alt={'green ticket'} width={75} style={{marginRight: '8px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                Green tickets are common. They are for a specific Landmark and are only good when it is open.
              </div>
            </div>

            <div className={'tutorial-tickets-row'}>
              <div>
                <img src={'./blue_ticket_small.svg'} alt={'green ticket'} width={75} style={{marginRight: '8px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                Blue tickets are uncommon. They are for a specific Landmark but allow access even when it is closed.
              </div>
            </div>

            <div className={'tutorial-tickets-row'}>
              <div>
                <img src={'./gold_ticket_small.svg'} alt={'green ticket'} width={75} style={{marginRight: '8px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                Gold tickets are rare.  They can be used at any location at any time.
              </div>
            </div>

          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }


      {currentPage === 5 &&
        <div>
          <div className={'tutorial-header'}>Mini-Games</div>
          <div className={'tutorial-txt'} style={{marginBottom: '20px'}}>
            Tickets grant you entry to a Landmark in order to play the mini-game.
            Each Landmark has a mini-game that you will have to win in order to get the Souvenir for that Landmark.
          </div>
          <div>

            <div className={'tutorial-tickets-row'} style={{marginBottom: '24px'}}>
              <div>
                <img src={'./tutorial_game.svg'} alt={'Game SVG'} width={75} style={{marginRight: '8px', marginTop: '-10px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                Mini-games start out hard but every time you lose it gets easier the next time you play
              </div>
            </div>

            <div className={'tutorial-tickets-row'} style={{marginBottom: '24px'}}>
              <div>
                <img src={'./tutorial_levels.svg'} alt={'Levels SVG'} width={75} style={{marginRight: '8px', marginTop: '-10px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                There are three difficulty levels: Hard, Standard, Easy
              </div>
            </div>

            <div className={'tutorial-tickets-row'} style={{marginBottom: '24px'}}>
              <div>
                <img src={'./tutorial_win.svg'} alt={'Win SVG'} width={75} style={{marginRight: '8px', marginTop: '-10px'}}/>
              </div>
              <div className={'tutorial-tickets-txt'}>
                Once you win the Souvenir for a Landmark, the mini-game resets to the starting difficulty
              </div>
            </div>

          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => nextPage()} className={'tutorial-btn'}>Next</Button>
          </div>
        </div>
      }

      {currentPage === 6 &&
        <div>
          <div className={'tutorial-header'}>Trading</div>
          <div className={'tutorial-txt'} style={{marginBottom: '20px'}}>
            Souvenirs can be traded with other players to help fill your collection.
            Only duplicates can be traded. There are two types of trades: Smart and Manual
          </div>
          <div>
            <div className={'tutorial-txt-trade'} style={{marginBottom: '20px'}}>
              A <span style={{fontFamily: 'AntipastoBold'}}>Smart Trade</span> is the easiest way to get a Souvenir that you don’t already own.
              You simply pick Souvenirs that you have multiples of and the trade engine will find players that can fulfill the trade.
              All Smart Trades are one for one and you can’t control who you are trading with.
            </div>

            <div className={'tutorial-txt-trade'}>
              A <span style={{fontFamily: 'AntipastoBold'}}>Manual Trade</span> allows you to specify a player and specific Souvenirs to trade.
              This allows you to send a trade request to a specific player perhaps after negotiating with them in the Trading Chat Room.
              Manual Trades also allow for multiple Souvenirs to be traded in one transaction, but only a maximum of 3 per person per trade.
            </div>

          </div>
          <div className={'tutorial-btn-container-double'}>
            <Button onClick={() => prevPage()} className={'tutorial-btn'}>Prev</Button>
            <Button onClick={() => props.closeDialog()} className={'tutorial-btn'}>Done</Button>
          </div>
        </div>
      }

    </div>
  )
}
export default Tutorial