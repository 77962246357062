
import React from "react";
import "./notificaionsGlobalCompletion.scss";

function NotificationsGlobalCompletion(props: any) {

  return (
    <div className='notifications-global-completion-container'>
      <div className='notifications-global-completion-title'>
        We have a new winner!
      </div>
      <div className='notifications-global-completion-subtitle'>
        Player {props.notification.metadata.player} has just earned
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className='notifications-global-completion-circle'>
          <img alt={''} src={'winner_star.png'} />
          <div className='notifications-global-completion-place'>
            <div>
              {props.notification.metadata.place}
              {props.notification.metadata.place === 1 && <span>st</span>}
              {props.notification.metadata.place === 2 && <span>nd</span>}
              {props.notification.metadata.place === 3 && <span>rd</span>}
              {props.notification.metadata.place === 4 && <span>th</span>}
              {props.notification.metadata.place === 5 && <span>th</span>}
            </div>
            <div style={{fontSize: '18px'}}>Place</div>
          </div>
        </div>
      </div>
      <div style={{textAlign: 'center'}}>
        <div className='notifications-global-completion-title'>
          Stats
        </div>
        <div className='notifications-global-completion-stats'>
          Total Souvenirs collected: {props.notification.metadata.total_souvenirs}
        </div>
        <div className='notifications-global-completion-stats'>
          Souvenirs from vouchers: {props.notification.metadata.souvenirs_from_vouchers}
        </div>
        <div className='notifications-global-completion-stats'>
          Souvenirs from trading: {props.notification.metadata.souvenirs_from_trades}
        </div>
      </div>
    </div>
  )
}
export default NotificationsGlobalCompletion