import './faq.scss';
import React from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function Faq() {

  return (
    <div style={{minHeight: '100vh', paddingTop: '50px'}}>
      <AppHeader title={'FAQ'}/>
      <div>
        <div className={'faq-header'}>FAQ</div>
        <div style={{padding: '0 8px'}}>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>How to play?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                Each day you’ll receive up to 3 Vouchers automatically that you then give to a travel agent of your
                choice and they’ll provide you with a Ticket, unless you pick a high risk agent who might lose your Voucher.
                Tickets then let you play the mini-games and each location has a unique souvenir you need to collect.
                Once you have all 30 you’ll place on the leaderboard and earn a reward!
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>What’s the goal?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                The goal is for you to collect Souvenirs from all 30 cities within the country.
                Win a mini-game at every location to earn a Souvenir, or trade with others.
                Once you have all 30 unique Souvenirs you’ll be on the winners board!
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>What are Vouchers and Tickets?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                Travel Vouchers are what you’re given daily, automatically.
                You give Vouchers to an assistant to book you a Ticket from the booking page.
                Tickets are what let you access specific locations to play mini-games.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>What’s the difference between the Ticket colors?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                Green Tickets are the basic level, they only let you play specific locations when the location is open.
                Blue Tickets let you access a specific location at any time. Golden Tickets let you go to any location
                at any time and are the most coveted!
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>How many Vouchers do I get a day?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                You will get up to 3 Standard Vouchers a day between the hours of 9am and 6pm Pacific.
                You can only hold 3 Standard Vouchers maximum at one time, so you MUST redeem them for
                Tickets in order for you to get the next days Vouchers.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>A location was closed and I lost my ticket?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                If a location is closed then the Ticket you got will go into your inventory.
                Remember, Vouchers and Tickets are different.
                You’ve redeemed your Voucher for a Ticket and the Ticket will remain in your inventory until you use it at the location.
                You’ll never lose a ticket because the location was closed.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>The assistant lost my ticket/misspelled my name!
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                Each booking assistant has their own percentages that you should check before selecting them.
                Some assistants can LOSE your Ticket and leave you stranded!
                But they also tend to have more luck finding those Golden Tickets too. Is the risk worth it? 🤔
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>The hard games are too hard 🙁
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                If you fail a hard or a medium mini-game, the difficulty is lowered the next time you play that location.
                All locations will eventually become easy if you fail them.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>What are Artifacts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt'>
                If I tell you, the evil wizards will definitely send me to the tower. Their secrets will be revealed soon though.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='faq-accordion-row' sx={{
            '&:before': {
              display: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className='faq-accordion-txt'>What are the prizes?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='faq-accordion-txt' component={"div"}>
                <div>
                  1st - $1000 Gift Card
                </div>
                <div>
                  2nd - $500 Gift Card
                </div>
                <div>
                  3rd - $300 Gift Card
                </div>
                <div>
                  4th - $150 Gift Card
                </div>
                <div>
                  5th - $75 Gift Card
                </div>
                <div>
                  6th to 10th - $50 Gift Card
                </div>
                <div>
                  11th to 20th - $25 Gift Card
                </div>
                <div>
                  21st to 100th - $15 Gift Card
                </div>
                <div>
                  101st to 200th - $10 Gift Card
                </div>
                <div>
                  201st to 500th - $5 Gift Card
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
    </div>
  )
}