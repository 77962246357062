import React, { useCallback, useEffect, useState } from 'react';
import './trivia.scss';
import { Box, Button, Dialog, DialogContent, DialogProps, LinearProgress, Radio } from "@mui/material";
import { triviaGameService } from '../../services/triviaGame';
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

export type TriviaQuestion = {
  question: string;
  choices: string[];
}

type TimerNum = null | number;

export type TriviaQuestionWithAnswer = TriviaQuestion & {
  answer: number;
}
function Trivia(props: {landmark_id: string, landmark_name: string}) {

  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState<TimerNum>(null);
  const [questions, setQuestions] = useState<TriviaQuestion[]>([]);
  const [answers, setAnswers] = useState<TriviaQuestionWithAnswer[]>([]);
  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [gameDisabled, setGameDisabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({
    question: '',
    choices: [] as string[]
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [totalTime, setTotalTime] = useState(0);

  const setupGame = useCallback(() => {
    triviaGameService.getGame(props.landmark_id).then((r) => {
      if(r && !r.error) {
        if(r.metadata && r.metadata.time_limit) {
          setTotalTime(r.metadata.time_limit);
        }
        if(r.metadata && r.metadata.remaining_time) {
          setTimer(r.metadata.remaining_time);
          setProgress((r.metadata.time_limit - r.metadata.remaining_time) * (100 / r.metadata.time_limit));
        }
        if(r.metadata && r.metadata.questions) {
          setQuestions(r.metadata.questions);
          if(r.metadata.questions.length > 0) {
            setCurrentIndex(0);
            setCurrentQuestion(r.metadata.questions[0]);
          }
        }
      }
      if(r && r.error) {
        setOpenFailedDialog(true);
      }
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if(oldProgress > 100) {
          clearInterval(timer);
          return 100;
        }
        return (totalTime !== 0) ? oldProgress + 100 / totalTime : 0;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [totalTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer((oldTimer) => {
        if(oldTimer === 0) {
          clearInterval(timer);
          submit();
          return 0;
        }
        return (oldTimer !== null ) ? oldTimer - 1 : oldTimer;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (index: number, q: TriviaQuestion) => {
    const tmp = {...q, answer: index};
    if(!answers.find(tmp => tmp.question === q.question)) {
      const tmpAnswers = [...answers, tmp];
      setAnswers(tmpAnswers);
    } else {
      const tmpAnswers = answers.map((ans) => {
        return ans.question === tmp.question ? tmp : ans;
      })
      setAnswers(tmpAnswers);
    }
  }

  const isRadioChecked = (q: TriviaQuestion, index: number) => {
    let found = false;
    for(let i = 0; i < answers.length; i++) {
      if((answers[i].question === q.question && answers[i].answer === index)) {
        found = true;
      }
    }
    return found;
  }

  const submit = () => {
    setBtnDisabled(true);
    setTimer(null);
    if(!gameDisabled) {
      triviaGameService.submitAnswers(answers, props.landmark_id).then((r) => {
        setGameDisabled(true);
        if(r.status && r.status === 'success') {
          setOpenCongratulationsDialog(true);
        }
        if(r.status && r.status === 'failed') {
          setOpenFailedDialog(true);
        }
        if(r && r.error) {
          setOpenFailedDialog(true);
        }
      })
    }
  }

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  const nextQuestion = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    setCurrentQuestion(questions[newIndex]);
  }

  const prevQuestion = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    setCurrentQuestion(questions[newIndex]);
  }

  return (
    <div style={{minHeight: '100vh', padding: '12px 12px', background: '#F8BD5D'}}>
      <div className={'trivia-game-header'}>
        {props.landmark_name}
      </div>
      <div className={'trivia-game-subheader'}>Trivia game</div>
      <div className={'trivia-game-description'}>
        Test your knowledge! Select the correct answer before the time limit to win the souvenir.
      </div>
      <div className={'trivia-game-question-header'}>
        Question {currentIndex + 1} of {questions.length}:
      </div>
      <div className={'trivia-game-question'}>
        {currentQuestion.question}
      </div>
      <div className='trivia-answer-container'>
        <div>
          <div>
            <Box sx={{ width: '100%' }}>
              <div style={{marginBottom: '8px', padding: '0 12px'}}>
                <LinearProgress
                  sx={{
                    height: '12px',
                    borderRadius: '8px',
                    background: '#000',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FFF'
                    }
                  }} variant="determinate" value={progress} />
              </div>
              <div className='trivia-timer-container'>
                Time remaining: {timer} seconds
              </div>
            </Box>
          </div>
          {currentQuestion.choices.map((ch, index) =>
            <div key={index} style={{display: 'flex'}}>
              <div>
                <Radio
                  sx={{color: "#FFF", '&, &.Mui-checked': {
                      color: '#FFF',
                    },}}
                  onChange={() => handleChange(index, currentQuestion)}
                  value={ch}
                  checked={isRadioChecked(currentQuestion, index)}
                />
              </div>
              <div className='trivia-choice-txt'>
                {ch}
              </div>
            </div>
          )}
        </div>

        <div className={'trivia-btn-container'}>
          <Button onClick={() => prevQuestion()} disabled={currentIndex === 0} className={(currentIndex !== 0)  ? 'trivia-game-btn' : 'trivia-game-btn-dsbl'}>Prev</Button>
          {(currentIndex + 1) === questions.length ?
            <Button
              disabled={answers.length !== 3  || btnDisabled}
              className={(answers.length === 3 && !btnDisabled)  ? 'trivia-game-btn' : 'trivia-game-btn-dsbl'}
              onClick={() => submit()}>Submit</Button>
            :
            <Button onClick={() => nextQuestion()} className={'trivia-game-btn'}>Next</Button>
          }
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name} closeDialog={handleCloseCongratulationsDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog} />
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default Trivia