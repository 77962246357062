import './map.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cityService } from "../../services/city";
import Loading from "../../components/Loading/Loading";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Snackbar } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';


type City = {
  _id: string;
  name: string;
  country_id: string;
}

type Cities = City[]

type Landmark = {
  _id: string;
  name: string;
  owned: number;
  spares: number;
  image_url: string;
}

type SelectedCity = City & {
  description: string;
  landmarks: Landmark[];
}


export function Map() {

  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<Cities>([]);
  const [selectedCity, setSelectedCity] = useState<SelectedCity>({
    _id: '',
    name: '',
    country_id: '',
    description: '',
    landmarks: []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const onButtonClick = (city: string) => {
    const foundCity = cities.find((c) => {
      return c.name.toLowerCase() === city.toLowerCase();
    });
    if(foundCity) {
      cityService.getCity(foundCity._id).then((res: SelectedCity) => {
        if(res) {
          setSelectedCity(res);
          setIsSelected(true);
          setSearchParams({city: res._id});
        }
      })
    }
  }

  const navigateToLandMark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getCities = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      cityService.getCities().then((r: {cities: Cities, error?: boolean, message: string}) => {
        if(r.cities) {
          setCities(r.cities);
          const city = searchParams.get('city');
          if(city !== null) {
            const foundCity = r.cities.find((c) => {
              return c._id === city;
            });
            if(foundCity) {
              cityService.getCity(foundCity._id).then((res: SelectedCity) => {
                if(res) {
                  setSelectedCity(res);
                  setIsSelected(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              })
            }
          } else {
            setIsLoading(false);
          }
        }
        if(r.error) {
          setPopupMessage(r.message);
          setShowPopup(true);
          setIsLoading(false);
        }
      })
    })
  }, [searchParams])

  useEffect(() => {
    getCities();
  }, [getCities]);

  const resetMap = () => {
    setIsSelected(false);
    setSelectedCity({
      _id: '',
      name: '',
      country_id: '',
      description: '',
      landmarks: []
    });
    searchParams.delete('city');
    setSearchParams(searchParams);
  }

  const navigateToTradeHub = () => {
    navigate(`/trade_hub`);
  }

  const navigateToBooking = () => {
    navigate(`/booking`);
  }

  return (
    <div className={'map-bg'}>
      <AppHeader title={'Map'}/>
      {isLoading && <Loading  color={''}/>}
      {!isLoading &&
        <div className={'map-inner-bg'} style={{background: "", height: '100vh', padding: '16px'}}>
          <div>
            <div className={'map-breadcrumb'} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{paddingTop: '7px', paddingLeft: '6px', display: 'flex', marginRight: '16px'}}>
                  <div>
                    <img style={{marginRight: '8px'}} alt={'map pin'} src={'./map_pin.svg'} width={17} height={21} />
                  </div>
                  <div onClick={() => resetMap()} className={'map-breadcrumb-txt'}>Greece</div>
                </div>
                {isSelected && <div style={{paddingTop: '7px', display: 'flex'}}>
                  <div>
                    <img style={{marginRight: '8px'}} src={'./city_icon.svg'} alt={'city icon'} width={17} height={21} />
                  </div>
                  <div className={'map-breadcrumb-txt-city'}>{selectedCity.name}</div>
                </div>}
              </div>
              <div>
                {/*Right*/}
                <div style={{paddingTop: '3px'}}>
                  <img style={{marginRight: '8px'}} alt={'breadcrumb icon'} src={'./breadcrumb_icon.svg'} width={29} height={29} />
                </div>
              </div>
            </div>
            <div className={'map-icons-container'}>
              <div className={'map-icon'} style={{marginRight: '12px'}} onClick={() => navigateToTradeHub()}>
                <LoopOutlinedIcon style={{color: '#F9FED5'}} height={'30px'} width={'30px'}  />
              </div>
              <div className={'map-icon'} onClick={() => navigateToBooking()}>
                <LocalActivityIcon style={{color: '#F9FED5'}} height={'24px'} width={'24px'}  />
              </div>
            </div>


            {!isSelected ?
              <div style={{display:'flex', justifyContent: 'center', width: '100%', marginBottom: '12px'}}>

                <div style={{position: 'relative'}}>
                  <img src={'./greece_map.svg'} alt={'Greece'} />
                  <div onClick={() => onButtonClick('thessaloniki')} style={{top: "23px", left: '122px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Thessaloniki</div>
                  </div>
                  <div onClick={() => onButtonClick('crete')} style={{top: "405px", right: '160px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Crete</div>
                  </div>
                  <div onClick={() => onButtonClick('athens')} style={{top: "212px", left: '120px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Athens</div>
                  </div>
                  <div onClick={() => onButtonClick('sparta')} style={{top: "275px", left: '53px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Sparta</div>
                  </div>
                  <div onClick={() => onButtonClick('delphi')} style={{top: "167px", left: '60px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Delphi</div>
                  </div>
                  <div onClick={() => onButtonClick('thessaly')} style={{top: "85px", left: '85px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Thessaly</div>
                  </div>
                  <div onClick={() => onButtonClick('mykonos')} style={{top: "264px", right: '99px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Mykonos</div>
                  </div>
                  <div onClick={() => onButtonClick('dodecanese isles')} style={{top: "316px", right: '0px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Dodecanese Isles
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('zakynthos')} style={{top: "200px", left: '5px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('zakynthos')} style={{top: "230px", left: '13px', position: 'absolute'}}>
                    <div className={'map-city-label'}>Zakynthos</div>
                  </div>

                  <div onClick={() => onButtonClick('corfu')} style={{top: "68px", left: '3px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('corfu')} style={{top: "100px", left: '7px', position: 'absolute'}}>
                    <div className={'map-city-label'}>Corfu</div>
                  </div>


                </div>
              </div>
              :
              <div>

                {selectedCity.name.toLowerCase() === 'athens' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./athens_bg.svg'} alt={'Athens Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'ancient agora' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '25px', left: '17px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} height={50} src={l.image_url} alt={'ancient agora'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'parthenon' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '185px', left: '105px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={116} height={50} src={l.image_url} alt={'Parthenon'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'temple of olympian zeus' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '245px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={68} height={60} src={l.image_url} alt={'Temple of Olympian Zeus'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


                {selectedCity.name.toLowerCase() === 'delphi' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./delphi_bg.svg'} alt={'Delphi Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'ancient theatre' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '55px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Ancient Theatre'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'the temple of apollo' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '157px', left: '100px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'The Temple of Apollo'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'athenian treasury' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '257px', right: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Athenian Treasury'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'corfu' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./corfu_bg.svg'} alt={'Corfu Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'saint spyridon church' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '0px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={42} height={70} src={l.image_url} alt={'Saint Spyridon Church'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'the old fortress' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '80px', right: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'The Old Fortress'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'achillion palace' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '275px', right: '65px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Achillion Palace'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'sparta' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./sparta_bg.svg'} alt={'Sparta Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'tomb of leonidas' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '75px', left: '12px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={50} src={l.image_url} alt={'Tomb of Leonidas'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'menelaion' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '200px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'menelaion'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'museum of sparta' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '285px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={50} src={l.image_url} alt={'Museum of Sparta'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'crete' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./crete_bg.svg'} alt={'Crete Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'knossos palace' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '185px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={64} src={l.image_url} alt={'Knossos Palace'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'old venetian harbor' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '132px', left: '100px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Old Venetian Harbor'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'samaria gorge' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '210px', left: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'Samaria Gorge'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'dodecanese isles' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./dodecanese_isles_bg.svg'} alt={'Dodecanese Isles Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'valley of the butterflies' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '285px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'Valley of the Butterflies'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'hippocrates tree' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '80px', left: '42px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={66} src={l.image_url} alt={'Hippocrates Tree'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'saint john monastery' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '232px', left: '75px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={66} src={l.image_url} alt={'Saint John Monastery'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'mykonos' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./mykonos_bg.svg'} alt={'Mykonos Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'old port' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '15px', left: '125px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Old Port'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'little venice' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '152px', left: '125px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={95} src={l.image_url} alt={'Little Venice'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'windmills' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '305px', left: '50px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Windmills'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'zakynthos' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./zakynthos_bg.svg'} alt={'Zakynthos Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'blue caves' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '10px', left: '47px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Blue Caves'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'venetian castle' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '210px', left: '13px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Venetian Castle'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'agios dionysios church' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '235px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Agios Dionysios Church'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'thessaloniki' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./thessaloniki_bg.svg'} alt={'Thessaloniki Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'the white tower' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', right: '110px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'The White Tower'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'hagia sophia' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '145px', left: '70px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Hagia Sophia'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'byzantine walls' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '40px', right: '45px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Byzantine Walls'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'thessaly' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./thessaly_bg.svg'} alt={'Thessaly Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'meteora' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '200px', left: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Meteora'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'trikala fort' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '245px', left: '105px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Trikala Fort'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'mount olympus' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '40px', left: '75px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Mount Olympus'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


              </div>
            }


          </div>
        </div>
      }
      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}