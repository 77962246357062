import React from "react";
import { CircularProgress } from "@mui/material";
import './loading.css';

type LoadingProps = {
  color: string;
}

function Loading(props: LoadingProps) {

  return (
    <div className="loading-container">
      <CircularProgress style={{'color': props.color}} />
    </div>
  )
}
export default Loading