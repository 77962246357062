import { TriviaQuestionWithAnswer } from "../components/Trivia/Trivia";

export const triviaGameService = {
  getGame: async function (landmark_id: string) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/game/start_game?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify({
        "landmark_id": landmark_id
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          // eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        console.log(error, "error")
        return {error: true, data: error};
      });
  },
  submitAnswers: async function (answers: TriviaQuestionWithAnswer[], landmark_id: string) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/game/trivia?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify({
        "landmark_id":landmark_id,
        "questions": answers
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          // eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        console.log(error, "error")
        return {error: true, data: error};
      });
  },

}