import './createManualTrade.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import Loading from "../../components/Loading/Loading";
import { tradeService } from "../../services/trade";
import {
  Autocomplete,
  Button,
  Checkbox, debounce, Dialog, DialogContent, DialogProps,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent, Snackbar,
  TextField, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CloseIcon from '@mui/icons-material/Close';


type Souvenir = {
  _id: string;
  name: string;
  count: number;
  city_name: string;
}

type AvailableUser = {
  name: string;
  value: string;
}

type TradeSetupResponse = {
  available_users: AvailableUser[];
  souvenirs: Souvenir[];
  user_spares: Souvenir[];
}
export function CreateManualTrade() {
  const [isLoading, setisLoading] = useState(false);
  const [availableSpares, setAvailableSpares] = useState<Souvenir[]>([]);
  const [selectedSpares, setSelectedSpares] = useState<Souvenir[]>([]);
  const [availableSouvenirs, setAvailableSouvenirs] = useState<Souvenir[]>([]);
  const [selectedSouvenir, setSelectedSouvenir] = useState<Souvenir[]>([]);
  const navigate = useNavigate();
  const [userID, setUserID] = useState({name: '', value: ''});
  const [availableUsers, setAvailableUsers] = useState<AvailableUser[]>([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarTxt, setSnackBarTxt] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);



  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setupTrade = useCallback(() => {
    setisLoading(true);
    sleep(1000).then(() => {
      tradeService.tradeSetup({'type': 'manual'}).then((r: TradeSetupResponse) => {
        setAvailableUsers([...r.available_users]);
        setAvailableSpares(r.user_spares);
        setAvailableSouvenirs([...r.souvenirs]);
        setSelectedSouvenir([]);
        setSelectedSpares([]);
        setUserID({name: '', value: ''});
        setisLoading(false);
      })
    })
  }, [])

  useEffect(() => {
    setupTrade();
  }, [setupTrade]);

  const handleSouvenirChange = (event: SelectChangeEvent<any>) => {
    setSelectedSpares(event.target.value);
  };

  const handleItemsToReceiveChange = (event: SelectChangeEvent<any>) => {
    setSelectedSouvenir(event.target.value);
  };

  const navigateToTradeHub = () => {
    navigate('/trade_hub');
  }

  const createTrade = () => {
    const trade = {
      type: "manual",
      direct_user_id: userID.value,
      requesting_souvenirs: selectedSouvenir.map((souv) => {
        return souv._id;
      }),
      trading_souvenirs: selectedSpares.map((spare) => {
        return spare._id;
      })
    };
    tradeService.createTrade(trade).then((r) => {
      if(r && r.message) {
        setSnackBarTxt(r.message);
        setSnackBarOpen(true);
        setupTrade();
      }
    })
  }

  const fetchUsers = (e: any) => {
    if(e && e.target && e.target.value) {
      tradeService.tradeSetup({find_user: e.target.value}).then((r: TradeSetupResponse) => {
        setAvailableUsers([...r.available_users]);
      })
    }
  }

  const debouncedFetchUsers = debounce(fetchUsers, 500);

  const selectUser = (e: any, newValue: any) => {
    if(newValue === null) {
      setUserID({name: '', value: ''});
    } else {
      setUserID(newValue);
    }
    if(newValue) {
      tradeService.tradeSetup({selected_user: newValue.value}).then((r: TradeSetupResponse) => {
        setAvailableUsers([...r.available_users]);
        setAvailableSouvenirs(r.souvenirs);
        setAvailableSpares(r.user_spares);
        setSelectedSouvenir([]);
        setSelectedSpares([]);
      })
    }
  }

  const openHelpDialog = () => {
    setDialogOpen(true);
  }

  const closeHelpDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setDialogOpen(false);
  }


  return (
    <div style={{minHeight: '100vh', background: '#FBF8EE', paddingTop: '50px'}}>
      <AppHeader title={'Create Trade'}/>
      <div style={{padding: '12px'}}>
        {isLoading && <Loading color={'#E81961'} />}
        {!isLoading &&
          <div>
            <div className={'create-manual-trade-container-header'}>
              <div style={{paddingTop: '1px'}}><ArrowBackIosIcon onClick={() => navigateToTradeHub()} style={{color: '#5F607B'}} /></div>
              <div>New Manual Trade</div>
              <div style={{paddingTop: '1px'}}><QuestionMarkIcon onClick={() => openHelpDialog()} style={{background: '#63CAF3', color: '#FFF', borderRadius: '12px', fontSize: '18px'}} /></div>
            </div>
            <div className="create-manual-trade-description" style={{marginBottom: '24px'}}>
              Find what you want while helping someone out!
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-manual-trade-form-lbl'>
                Player to trade with *
              </div>


              <Autocomplete
                className={'create-manual-trade-li'}
                value={userID}
                onChange={(event: any, newValue: any) => {
                  selectUser(event, newValue);
                }}
                sx={{
                  "& .MuiAutocomplete-input": {
                    color: '#000',
                    fontFamily: 'AntipastoRegular',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                  }
                }}
                onInputChange={(e) => debouncedFetchUsers(e)}
                disablePortal
                renderOption={(props, option) => (
                  <Typography style={{color: '#000',
                    fontFamily: 'AntipastoRegular',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',}} {...props}>
                    {/*@ts-ignore*/}
                    {option.name}
                  </Typography>
                )}
                size={'small'}
                options={availableUsers}
                // @ts-ignore
                getOptionLabel={(option) => {
                  if(option.name) {
                    // @ts-ignore
                    return option.name;
                  } else {
                    return '';
                  }
                }}
                renderInput={(params: any) => <TextField placeholder={'Start typing to find player'} {...params} />}
              />
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-manual-trade-form-lbl'>
                Souvenir(s) to Give * <span className={'create-manual-trade-form-sub'}>Max 3</span>
              </div>
              <FormControl fullWidth>
                <Select
                  multiple
                  className={'create-manual-trade-li'}
                  value={selectedSpares}
                  onChange={handleSouvenirChange}
                  renderValue={(selected) => selected.map((s) => s.name).join(', ')}
                >
                  {availableSpares.map((spare: any) => (
                    <MenuItem style={{height: '38px', minHeight: '38px'}} sx={{
                      "& .MuiTypography-root": {
                        color: '#000',
                        fontFamily: 'AntipastoRegular',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      }
                    }} key={spare._id} value={spare}>
                      <Checkbox checked={selectedSpares.indexOf(spare) > -1} />
                      <ListItemText primary={spare.city_name + ': ' + spare.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-manual-trade-form-lbl'>
                Souvenir(s) to Get * <span className={'create-manual-trade-form-sub'}>Max 3</span>
              </div>
              <FormControl fullWidth>
                <Select
                  multiple
                  className={'create-manual-trade-li'}
                  value={selectedSouvenir}
                  onChange={handleItemsToReceiveChange}
                  renderValue={(selected) => selected.map((s) => s.name).join(', ')}
                >
                  {availableSouvenirs.map((spare: any) => (
                    <MenuItem style={{height: '38px', minHeight: '38px'}} sx={{
                      "& .MuiTypography-root": {
                        color: '#000',
                        fontFamily: 'AntipastoRegular',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      }
                    }} key={spare._id} value={spare}>
                      <Checkbox checked={selectedSouvenir.indexOf(spare) > -1} />
                      <ListItemText primary={spare.city_name + ': ' + spare.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={'create-manual-trade-btn'}>
              <Button style={{width: '260px'}}
                      onClick={() => createTrade()}
                      disabled={(selectedSpares.length === 0 || selectedSouvenir.length === 0 || (userID && userID.value) === '') || selectedSpares.length > 3 || selectedSouvenir.length > 3 }
                      className={(selectedSpares.length === 0 || selectedSouvenir.length === 0 || (userID && userID.value === '') || selectedSpares.length > 3 || selectedSouvenir.length > 3 ? 'create-trade-btn-dsbl' : 'create-trade-btn')}
              > Confirm
              </Button>
            </div>
          </div>}
      </div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarTxt}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={closeHelpDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "14px",
              background: "#FFF",
            },
          },
        }}
      >
        <DialogContent>
          <div>
            <div  onClick={() => closeHelpDialog({}, 'escapeKeyDown')} style={{color: "#CCCCCC", position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
              <CloseIcon width={'28px'} height={'28px'} />
            </div>
            <div className={'manual-trade-help-title'}>What is a Manual Trade?</div>
            <div className={'manual-trade-help-txt'} style={{marginTop: '16px', marginBottom: '12px'}}>
              Manual trades are the kind of trade you would make playing a tabletop game with the family.
              You talk it over with the other party, they agree, and then you complete the transaction here.
              Generally, these are set up in advance and agreed to before you send the request.
              You can also add in more complex trades including multi-party trades and more.
            </div>
            <div className={'manual-trade-help-txt'}>
              Sure, you can send a trade without chatting with the other party first, but your close rate might end up being lower that way!
            </div>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  )
}