import React from "react";
import "./notificationsPostFix.scss";

function NotificationsPostFix(props: any) {

  return (
    <div className='notifications-post-fix-container'>
      <div className='notifications-post-fix-title'>
        {props.notification.metadata.title}
      </div>
      <div  className='notifications-post-fix-description'>
        Firstly we want to say thank you for your patience, all bugged souvenirs have now been removed and trades undone
        in order to maintain fair gameplay, this may mean you're missing some souvenirs you thought you'd traded for or
        had duplicates of and that's intentional. If you think you're missing souvenirs you earned legitimately via mini
        games please reach out to support in RewardRush and we'll review that and fix where necessary.
      </div>
      <div  className='notifications-post-fix-description'>
        Trades and mini-games are now back open, and we're once again waiting for our first place winners!
        Remember we have 500 winner spots for this tour, but you must have all 30 unique souvenirs to claim your place.
      </div>
    </div>
  )
}
export default NotificationsPostFix