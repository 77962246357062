import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from "react-router-dom";
import Protected from "./components/Protected/Protected";
import { Leaderboard } from "./pages/Leaderboard/Leaderboard";
import { Profile } from "./pages/Profile/Profile";
import { Home } from "./pages/Home/Home";
import { Map } from "./pages/Map/Map";
import { TradeHub } from "./pages/TradeHub/TradeHub";
import { Chat } from "./pages/Chat/Chat";
import { Booking } from "./pages/Booking/Booking";
import { Inventory } from "./pages/Inventory/Inventory";
import { Landmark } from "./pages/Landmark/Landmark";
import { CreateSmartTrade } from "./pages/CreateSmartTrade/CreateSmartTrade";
import { CreateManualTrade } from "./pages/CreateManualTrade/CreateManualTrade";
import { Notifications } from "./pages/Notifications/Notifications";
import Error from "./components/Error/Error";
import ErrorWithHeader from "./components/ErrorWithHeader/ErrorWithHeader";
import { Rewards } from "./pages/Rewards/Rewards";
import { Faq } from "./pages/Faq/Faq";

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route element={<Protected />}>
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/map" element={<Map />} />
            <Route path="/trade_hub" element={<TradeHub />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/landmark" element={<Landmark />} />
            <Route path="/create_smart_trade" element={<CreateSmartTrade />} />
            <Route path="/create_manual_trade" element={<CreateManualTrade />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/forbidden" element={<ErrorWithHeader />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/faq" element={<Faq />} />
          </Route>
          <Route path="/error" element={<Error />} />
          {/*<Route path="/login" element={<Login />} />*/}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
