import React, { useCallback, useEffect, useState } from "react";
import "./appHeader.css";
import { ReactSVG } from "react-svg";
import { Button, Dialog, DialogContent, DialogProps, Link, Menu, MenuItem } from "@mui/material";
import { notificationsService } from "../../services/notifications";
import { useNavigate } from "react-router-dom";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NotificationsAchievement from "../NotificationsAchievement/NotificationsAchievement";
import Tutorial from "../Tutorial/Tutorial";
import NotificationsGlobalInfo from "../NotificationsGlobalInfo/NotificationsGlobalInfo";
import NotificationsPostFix from "../NotificationsPostFix/NotificationsPostFix";
import QuizIcon from '@mui/icons-material/Quiz';
import NotificationsDiscord from "../NotificationsDiscord/NotificationsDiscord";
import { AvailableNotification } from "../../types/notifications";

type AppHeaderProps = {
  title: string
}

function AppHeader(props: AppHeaderProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [availableNotifications, setAvailableNotifications ] = useState<AvailableNotification[]>([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showStartOfSeason, setShowStartOfSeason] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToSettings = () => {
    navigate('/profile');
  }

  const handleCloseDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenDialog(false);
    const currentNotification = availableNotifications.shift();
    if(currentNotification) {
      const notification_ids = [currentNotification._id];
      notificationsService.markNotificationAsRead({notification_ids}).then((r) => {
        if(availableNotifications.length > 0) {
          setOpenDialog(true);
        }
      })
    }
  }

  const handleCloseTutorialDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setShowTutorial(false);
    localStorage.setItem('rr-tutorial-watched', String(true));
  }

  const [newNotifications, setNewNotifications] = useState(false);
  const getNotifications = useCallback(() => {
    notificationsService.getNotifications(true).then((r) => {
      if (r && r.notifications) {
        if(r.notifications.length > 0) {
          const tmpNotifications = [];
          for(let i = 0; i < r.notifications.length; i++) {
            if(r.notifications[i].popup_notification) {
              tmpNotifications.push(r.notifications[i]);
            }
          }
          if(tmpNotifications.length > 0) {
            setAvailableNotifications(tmpNotifications);
            setOpenDialog(true);
          }
        }
      }
      if(r.available_notifications) {
        setNewNotifications(true);
      }
    })
  }, [])

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    const tutorialWatched = localStorage.getItem('rr-tutorial-watched');
    if(tutorialWatched === null) {
      setShowTutorial(true);
    }
  }, []);

  useEffect(() => {
    const startOfSeasonWatched = localStorage.getItem('rr-start-of-season-3');
    if(startOfSeasonWatched === null) {
      setShowStartOfSeason(true);
    }
  }, []);

  const handleCloseStartOfSeasonDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setShowStartOfSeason(false);
    localStorage.setItem('rr-start-of-season-3', String(true));
  }

  const showTutorialDialog = () => {
    setShowStartOfSeason(false);
    localStorage.setItem('rr-start-of-season-3', String(true));
    localStorage.setItem('rr-tutorial-watched', String(true));
    setShowTutorial(true);
  }

  const getDialogContent = () => {
    if(availableNotifications.length > 0) {
      switch(availableNotifications[0].type) {
        case 'achievement':
          return <NotificationsAchievement notification={availableNotifications[0]} closeDialog={handleCloseDialog}/>;
        case 'global_notification':
          return <NotificationsGlobalInfo notification={availableNotifications[0]} closeDialog={handleCloseDialog}/>;
        case 'dup_souvenir_fix':
          return <NotificationsPostFix notification={availableNotifications[0]} closeDialog={handleCloseDialog}/>;
        case 'discord':
          return <NotificationsDiscord notification={availableNotifications[0]} closeDialog={handleCloseDialog}/>;
        default:
          return null;
      }
    }
    return null;
  }

  const navigateToBooking = () => {
    setShowStartOfSeason(false);
    localStorage.setItem('rr-start-of-season-3', String(true));
    navigate('/booking');
  }

  return (
    <div className='appHeader-container'>
      {/*@ts-ignore*/}
      <div onClick={(e) => handleClick(e)} style={{position: 'relative', paddingTop: "4px", paddingLeft: '4px', paddingRight: '4px', borderRadius: '4px'}} className={(open) ? 'appHeader-menu-icon-container-open' : 'appHeader-menu-icon-container-close'}>
        <ReactSVG
          className={(open) ? 'appHeader-menu-icon-open' : 'appHeader-menu-icon-close'}
          height={'24px'}
          width={'24px'}
          src={'./ham_closed.svg'}
        />
        {newNotifications && <div style={{left: '20px', bottom: '13px'}} className='appHeader-notifications-indicator'></div>}
      </div>
      {/*<div className='appHeader-current-page'>{props.title}</div>*/}
      <div>
        <ReactSVG onClick={navigateToSettings} height={'34px'} width={'34px'} src={'./account_circle.svg'} />
      </div>

      <Menu
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#FFFFFF",
            left: '10px !important',
            top: '40px !important'
          }
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuItem className='appHeader-menu-item' component={Link} href={'/home'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./home.svg'} /></div>
          <div>
            Home
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/map'}>
          <div style={{marginRight: '12px'}}>
            <ReactSVG height={'24px'} width={'24px'} src={'./map.svg'} />
          </div>
          <div>
            Map
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/leaderboard'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./leaderboard_logo.svg'} /></div>
          <div>
            Leaderboards
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/trade_hub'}>
          <div style={{marginRight: '12px'}}> <CurrencyExchangeIcon height={'24px'} width={'24px'}  /></div>
          <div>
            Trade Hub
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/chat'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./chat_logo.svg'} /></div>
          <div>
            Chat
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/inventory'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./inventory.svg'} /></div>
          <div>
            Inventory
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/profile'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./settings-logo.svg'} /></div>
          <div>
            Profile
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/notifications'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./bell.svg'} /></div>
          {newNotifications && <div className='appHeader-notifications-indicator'></div>}
          <div>
            Notifications
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/rewards'}>
          <div style={{marginRight: '12px'}}><ReactSVG height={'24px'} width={'24px'} src={'./rewards.svg'} /></div>
          <div>
            Rewards
          </div>
        </MenuItem>
        <MenuItem className='appHeader-menu-item' component={Link} href={'/faq'}>
          <div style={{marginRight: '12px'}}><QuizIcon height={'24px'} width={'24px'}  /></div>
          <div>
            FAQ
          </div>
        </MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: 0,
              padding: 0,
              marginTop: '24px'
            },
          },
        }}
      >
        <DialogContent className="leaderboard-dialog-container">
          <div  onClick={() => handleCloseDialog({}, 'escapeKeyDown')} style={{position: 'absolute', right: '4px', top: '4px', display: 'inline-block', zIndex: 100}}>
            <HighlightOffIcon width={'28px'} height={'28px'} />
          </div>
          <div>
            {getDialogContent()}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showTutorial}
        onClose={handleCloseTutorialDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: 0,
              marginTop: '24px',
              margin: 2
            },
          },
        }}
      >
        <DialogContent className="tutorial-dialog-container">
          <div  onClick={() => handleCloseTutorialDialog({}, 'escapeKeyDown')} style={{position: 'absolute', right: '4px', top: '4px', display: 'inline-block', zIndex: 100}}>
            <HighlightOffIcon width={'28px'} height={'28px'} />
          </div>
          <div>
            <Tutorial closeDialog={handleCloseTutorialDialog} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showStartOfSeason}
        onClose={handleCloseStartOfSeasonDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: 0,
              marginTop: '24px',
              margin: 2
            },
          },
        }}
      >
        <DialogContent className="tutorial-dialog-container">
          <div  onClick={() => handleCloseStartOfSeasonDialog({}, "escapeKeyDown")} style={{position: 'absolute', right: '4px', top: '4px', display: 'inline-block', zIndex: 100}}>
            <HighlightOffIcon width={'28px'} height={'28px'} />
          </div>
          <div>
            <div className={'start-of-season-header'}>Welcome to Greece</div>
            <div className={'start-of-season-subheader'}>Tour 3</div>
            <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'center'}}>
              <img src={'./rr_general.png'} width={275} alt={'General RewardRush'} />
            </div>
            <div className={'start-of-season-txt-bold'}>
              Welcome to the third edition of the RewardRush Tour, the country of Greece!
            </div>
            <div className={'start-of-season-txt-regular'}>
              RewardRush is a periodic game we have created to give our best users a chance at our biggest rewards.
              Every month or so, we will release a new “tour” for you to play, with a travel focused theme.
            </div>
            <div className={'start-of-season-txt-regular'} style={{fontWeight: '700'}}>
              Start by booking your tickets for Greece now!
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '8px'}}>
              <Button onClick={() => navigateToBooking()} className={'start-of-season-btn'}>BOOK TICKETS</Button>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button onClick={() => showTutorialDialog()} className={'start-of-season-btn'}>SHOW TUTORIAL</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  )
}
export default AppHeader