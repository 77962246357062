import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="719251727396-1q0sojq4phf9t42q8htpbhis84645plc.apps.googleusercontent.com">
    <BrowserRouter>
      {/*<React.StrictMode>*/}
      <App />
      {/*</React.StrictMode>*/}
    </BrowserRouter>
  </GoogleOAuthProvider>
);

