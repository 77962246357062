import React, { useCallback, useEffect, useState } from 'react';
import './hangmanGame.scss';
import { hangmanGameService } from "../../services/hangmanGame";
import { Dialog, DialogContent, DialogProps } from "@mui/material";
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

function HangmanGame(props: {landmark_id: string, landmark_name: string}) {
  const firstRow = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
  const secondRow = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
  const thirdRow = ['z', 'x', 'c', 'v', 'b', 'n', 'm'];

  const [gameBoard, setGameBoard] =  useState<string[]>([]);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [gameLevel, setGameLevel] = useState('');
  const [numberOfChances, setNumberOfChances] = useState(0);
  const [numberOfWrongMoves, setNumberOfWrongMoves] = useState(0);

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [gameDisabled, setGameDisabled] = useState(false);
  const [gameImg, setGameImg] = useState('');
  const [apiInProgress, setApiInProgress] = useState(false);

  const onLetterClick = (letter: string) => {

    if(!gameDisabled && !apiInProgress && !guesses.includes(letter)) {
      setGuesses([...guesses, letter]);
      setApiInProgress(true);
      hangmanGameService.hangmanGuess(letter, props.landmark_id).then((r) => {
        setGameBoard(r.hangman);
        setGuesses(r.guessed);
        setNumberOfWrongMoves(r.wrong_guesses);
        setGameImg(r.image_url);
        if(r.status && r.status === 'success') {
          setOpenCongratulationsDialog(true);
          setGameDisabled(true);
        }
        if(r.status && r.status === 'failed') {
          setOpenFailedDialog(true);
          setGameDisabled(true);
        }
        setApiInProgress(false);
      })
    }
  }

  const getLetterClass = (letter: string) => {
    if (gameBoard.includes(letter) && guesses.includes(letter)) {
      return 'hangman-letter-correct';
    }
    if(guesses.includes(letter)) {
      return 'hangman-letter-incorrect';
    }
    return '';
  }

  const setupGame = useCallback(() => {
    hangmanGameService.getGame(props.landmark_id).then((r) => {
      if(r.metadata.hangman) {
        setGameBoard(r.metadata.hangman);
        setGuesses(r.metadata.guessed);
        setGameLevel(r.metadata.difficulty);
        setNumberOfChances(r.metadata.available_moves);
        setNumberOfWrongMoves(r.metadata.wrong_guesses);
        setGameImg(r.metadata.image_url);
      }
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  return (
    <div style={{minHeight: '100vh', background: '#444550'}}>

      <div style={{background: '#F8BD5D', paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'hangman-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'hangman-game-subheader'}>Guess the word game</div>
        <div className={'hangman-game-description'}>
          Guess the word to win the {props.landmark_name} Souvenir!
          You have limited tries to succeed but if you fail your next attempt will be easier.
        </div>

        <div className={'hangman-game-clouds-container '}>
          <div className={'hangman-game-clouds'}>
            <img style={{marginRight: '60px', marginTop: '42px'}} width={90} alt={'cloud 1'} src={'./cloud_1.svg'} />
            <img width={130} height={96} alt={'cloud 2'} src={'./cloud_2.svg'} />
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img width={300} height={156} alt={'bus img'} src={gameImg} />
        </div>

        <div className={'hangman-game-stats'}>
          <div>
            <div className={'hangman-game-lbl'}>Difficulty</div>
            <div className={'hangman-game-val'}>{gameLevel}</div>
          </div>
          <div>
            <div className={'hangman-game-lbl'}>Number of tries</div>
            <div className={'hangman-game-val'}>{numberOfChances}</div>
          </div>
          <div>
            <div className={'hangman-game-lbl'}>Tries used</div>
            <div className={'hangman-game-val'}>{numberOfWrongMoves}</div>
          </div>
        </div>
      </div>

      <div className={'hangman-game-footer-container'}>
        <div className='hangman-game-word-container'>
          {gameBoard.map((tw, index) =>
            <div key={index} className='hangman-game-word-letter'>
              {guesses.includes(tw) ? <span className={'hangman-game-guessed-letter'}>{tw}</span> :  <span style={{color: '#FFF'}}>_</span> }
            </div>
          )}
        </div>

        <div className='hangman-game-keyboard-container'>

          <div style={{padding: "0 12px"}}>
            <div className='hangman-game-row'>
              {firstRow.map((l, index) =>
                <div key={index} className={'hangman-game-letter ' + getLetterClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
            <div className='hangman-game-row'>
              {secondRow.map((l, index) =>
                <div key={index} className={'hangman-game-letter ' + getLetterClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
            <div className='hangman-game-row'>
              {thirdRow.map((l, index) =>
                <div key={index} className={'hangman-game-letter ' + getLetterClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name} closeDialog={handleCloseCongratulationsDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog} />
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default HangmanGame