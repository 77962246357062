import React from "react";
import "./notificationsDiscord.scss";

function NotificationsDiscord(props: any) {

  return (
    <div className='notifications-discord-container'>
      <div className='notifications-discord-title'>
        RewardRush Chat
      </div>
      <div  className='notifications-discord-description'>
        We've opened our Official Discord! You can join it by clicking <a href={'https://discord.com/app/invite-with-guild-onboarding/Rkz7vJpQc2'}>here</a>.
      </div>
      <div  className='notifications-discord-description'>
        Discord will make it easier for you to swap between chats, upload screenshots for support queries, set up trades, and just show off your pets if you want!
      </div>
      <div  className='notifications-discord-description'>
        We only have text chats for now, so no need to feel nervous about speaking.
        It'll also allow us to announce the ends and starts of tours ahead of time more easily,
        as well as let you know about changes to the game, and maybe a sneaky giveaway here and there if there's enough users enjoying themselves.
      </div>
      <div  className='notifications-discord-description'>
        Additionally we can utilize Discord roles to make clear things like who wants to help others trade at the end of a tour,
        or which community members are exceptionally helpful and can probably answer questions you have.
      </div>
      <div  className='notifications-discord-description'>
        We hope you'll join us over here, setting up a Discord account is super simple.
        If you have a Facebook account it'll be similar to that but isn't linked to your real name etc.
      </div>
    </div>
  )
}
export default NotificationsDiscord