import React, { useCallback, useEffect, useState } from 'react';
import './slidePuzzle.scss';
import { Button, Dialog, DialogContent, DialogProps } from "@mui/material";
import FailedDialog from "../FailedDialog/FailedDialog";
import { slidePuzzleService } from "../../services/slide_puzzle";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

function SlidePuzzle(props: {landmark_id: string, landmark_name: string}) {
  const [gameBoard, setGameBoard] =  useState<({url: string} | null)[]>([]);

  const [gameLevel, setGameLevel] = useState('');
  const [availableMoves, setAvailableMoves] = useState(0);
  const [remainingMoves, setRemainingMoves] = useState(0);

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [fullImageURL, setFullImageURL] = useState('');
  const [apiInProgress, setApiInProgress] = useState(false);

  const setupGame = useCallback(() => {
    slidePuzzleService.getGame(props.landmark_id).then((r) => {
      if(r.metadata) {
        if(r.metadata.puzzle) {
          setGameBoard(r.metadata.puzzle);
        }
        if(r.metadata.difficulty) {
          setGameLevel(r.metadata.difficulty);
        }
        if(r.metadata.available_moves) {
          setAvailableMoves(r.metadata.available_moves);
        }
        if(r.metadata.remaining_moves) {
          setRemainingMoves(r.metadata.remaining_moves);
        }
        if(r.metadata.complete_image_url) {
          setFullImageURL(r.metadata.complete_image_url);
        }
      }
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  const handleCloseImageDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenImageDialog(false);
  }

  const handleCellClick = (n: number | null) => {
    if(n !== null && !apiInProgress) {
      setApiInProgress(true);
      slidePuzzleService.move(n, props.landmark_id).then((r) => {
        setApiInProgress(false);
        if(r.remaining_moves){
          setRemainingMoves(r.remaining_moves);
        }
        if(r.available_moves){
          setAvailableMoves(r.available_moves);
        }
        if(r.puzzle) {
          setGameBoard(r.puzzle);
        }
        if(r.status && r.status === 'success') {
          setOpenCongratulationsDialog(true);
        }

        if(r.status && r.status === 'failed') {
          setOpenFailedDialog(true);
        }
      })
    }
  }

  return (
    <div style={{minHeight: '100vh', background: '#444550'}}>

      <div style={{background: '#F8BD5D', paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'slide-puzzle-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'slide-puzzle-game-subheader'}>Slide Puzzle game</div>
        <div className={'slide-puzzle-game-description'}>
          Tap on a piece next to the empty space to move it in to the empty space,
          get all the tiles in the correct place to complete the image!
        </div>

        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
          <div className={'slide-puzzle-board'}>
            {gameBoard.map((item, index) =>
              <div key={index} className={'slide-puzzle-cell'} onClick={() => handleCellClick(index)}>
                {item !== null && <img width={66} src={item.url} alt={'Puzzle Square'}/>}
              </div>
            )}
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
          <Button onClick={() => setOpenImageDialog(true)} className={'slide-puzzle-btn'}>Show full image</Button>
        </div>

        <div className={'slide-puzzle-game-stats'}>
          <div>
            <div className={'slide-puzzle-game-lbl'}>Difficulty</div>
            <div className={'slide-puzzle-game-val'}>{gameLevel}</div>
          </div>
          <div>
            <div className={'slide-puzzle-game-lbl'}>Available Moves</div>
            <div className={'slide-puzzle-game-val'}>{availableMoves}</div>
          </div>
          <div>
            <div className={'slide-puzzle-game-lbl'}>Remaining Moves</div>
            <div className={'slide-puzzle-game-val'}>{remainingMoves}</div>
          </div>
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name} closeDialog={handleCloseCongratulationsDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <div>
            <div style={{marginBottom: '16px'}}>
              <img src={fullImageURL} width={240} alt={'Slide puzzle full'}/>
            </div>
            <div style={{width: '100%'}}>
              <Button onClick={() => setOpenImageDialog(false)} style={{width: '100%'}} className={'slide-puzzle-btn'}>Close</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default SlidePuzzle