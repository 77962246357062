import './leaderboard.scss';
import React, { useCallback, useEffect, useState } from "react";
import { leaderBoardService } from "../../services/leaderboard";
import Loading from '../../components/Loading/Loading';
import AppHeader from "../../components/AppHeader/AppHeader";

type LeaderboardEntry = {
  display_name: string;
  placement: number;
  total_count: number;
  user_id: string;
}

export function Leaderboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [leaderBoard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [userPlacement, setUserPlacement] = useState('');
  const [season, setSeason] = useState('');

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getLeaderboard = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      leaderBoardService.getLeaderboard().then((r) => {
        if (r.error) {
          setIsLoading(false);
        } else {
          if(r) {
            if(r.user_placement && r.user_placement.placement) {
              setUserPlacement(r.user_placement.placement);
            }
            if(r.season) {
              setSeason(r.season);
            }
            setLeaderboard(r.leaderboard);
          }
          setIsLoading(false);
        }
      })
    });

  }, []);

  useEffect(() => {
    getLeaderboard();
  }, [getLeaderboard]);

  return (
    <div className={'leaderboard-container'}>
      {isLoading ? <Loading color={'#E81961'} /> :
        <div>
          <AppHeader title={'Leaderboard'}/>
          <div className={'leaderboard-container-header'}>
            <div style={{width: '300px'}}>Leaderboard</div>
          </div>
          <div style={{maxWidth: '350px', margin: '0 auto'}}>
            <div className={'leaderboard-container-season'}>
              <div>Season {season}</div>
            </div>
            <div style={{marginBottom: '24px', overflowY: 'scroll', height: '70vh'}}>
              {leaderBoard.map((l: LeaderboardEntry, index) => {
                return (
                  <div key={index}>
                    <div className="leaderboard-row" key={index}>
                      <div className='leaderboard-row-standing' style={{width: "30px"}}>{l.placement}</div>
                      <div className='leaderboard-row-txt' style={{width: '260px', textAlign: 'left'}}>{l.display_name}</div>
                      <div  className='leaderboard-row-count'>{l.total_count}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <div className={'leaderboard-footer-txt'}>Your Placement</div>
            <div className={'leaderboard-footer-number'}>#{userPlacement}</div>
          </div>
        </div>
      }
    </div>
  )
}