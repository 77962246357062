export const homeService = {
  getDetails: async function () {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/home?rr_token=${lsToken}`)
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  }
}