import React, { useCallback, useEffect, useState } from 'react';
import './matchThree.scss';
import { Dialog, DialogContent, DialogProps } from "@mui/material";
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";
import { matchThreeService } from "../../services/matchThree";

type MatchThreeImg = {
  type: string;
  url: string;
}

function MatchThree(props: {landmark_id: string, landmark_name: string}) {
  const [gameBoard, setGameBoard] =  useState<any>([]);

  const [gameLevel, setGameLevel] = useState('');
  const [availableMoves, setAvailableMoves] = useState(0);
  const [remainingMoves, setRemainingMoves] = useState(0);

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [apiInProgress, setApiInProgress] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [requiredMatches, setRequiredMatches] = useState(0);
  const [imgArr, setImgArr] = useState<MatchThreeImg[]>([]);
  const [fromIndex, setFromIndex] = useState<[number, number] | null>(null);

  const setupGame = useCallback(() => {
    matchThreeService.getGame(props.landmark_id).then((r) => {
      if(r.metadata) {
        if(r.metadata.game_board) {
          setGameBoard(r.metadata.game_board);
        }
        if(r.metadata.difficulty) {
          setGameLevel(r.metadata.difficulty);
        }
        if(r.metadata.available_moves) {
          setAvailableMoves(r.metadata.available_moves);
        }
        if(r.metadata.remaining_moves) {
          setRemainingMoves(r.metadata.remaining_moves);
        }
        if(r.metadata.match_count) {
          setMatchCount(r.metadata.match_count);
        }
        if(r.metadata.required_matches) {
          setRequiredMatches(r.metadata.required_matches);
        }
        if(r.metadata.image_array) {
          setImgArr(r.metadata.image_array);
        }
      }
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  const handleCellClick = (rowIndex: number, cellIndex: number) => {
    if(fromIndex === null) {
      setFromIndex([rowIndex, cellIndex]);
    } else {
      setApiInProgress(true);
      if(!apiInProgress) {
        matchThreeService.move(fromIndex, [rowIndex, cellIndex], props.landmark_id).then((r) => {
          if(r.game_board) {
            setGameBoard(r.game_board);
          }
          if(r.remaining_moves) {
            setRemainingMoves(r.remaining_moves);
          }
          if(r.match_count) {
            setMatchCount(r.match_count);
          }

          if(r.status && r.status === 'success') {
            setOpenCongratulationsDialog(true);
          }

          if(r.status && r.status === 'failed') {
            setOpenFailedDialog(true);
          }


          setFromIndex(null);
          setApiInProgress(false);
        })
      }
    }
  }

  const getImgSrc = (item: string) => {
    let url = '';

    for(let i = 0; i < imgArr.length; i++) {
      if(imgArr[i].type === item) {
        url = imgArr[i].url;
      }
    }

    return url;
  }

  const getHighlightedCell = (rowIndex: number, cellIndex: number) => {
    if(fromIndex !== null && (fromIndex[0] === rowIndex && fromIndex[1] === cellIndex)) {
      return 'match-three-highlighted-cell';
    }
    return '';
  }


  return (
    <div style={{minHeight: '100vh', background: '#444550'}}>

      <div style={{background: '#F8BD5D', paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'match-three-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'match-three-game-subheader'}>Match Three game</div>
        <div className={'match-three-game-description'}>
          Match the dots in straight lines to collect them and add them towards your required total.
        </div>

        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
          <div>
            {gameBoard.map((row: any, rowIndex: number) =>
              <div key={rowIndex} style={{display: 'flex'}}>
                {row.map((item: any, cellIndex: number) =>
                  <div key={cellIndex}>
                    <img alt={'Match Three Cell'} className={getHighlightedCell(rowIndex, cellIndex)} width={45} src={getImgSrc(item)} onClick={() => handleCellClick(rowIndex, cellIndex)}/>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={'match-three-game-stats'}>
          <div>
            <div className={'match-three-game-lbl'}>Difficulty</div>
            <div className={'match-three-game-val'}>{gameLevel}</div>
          </div>
          <div>
            <div className={'match-three-game-lbl'}>Total dots cleared</div>
            <div className={'match-three-game-val'}>{matchCount}/{requiredMatches}</div>
          </div>
          <div>
            <div className={'match-three-game-lbl'}>Moves</div>
            <div className={'match-three-game-val'}>{remainingMoves}/{availableMoves}</div>
          </div>
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name} closeDialog={handleCloseCongratulationsDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog} />
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default MatchThree