import React from "react";
import './error.scss';

function Error() {

  return (
    <div className={'error-container'}>
      <div className={'error-container-header'}>
        <div style={{width: '300px', marginTop: '8px'}}>Dead End</div>
      </div>
      <div className={'error-img-container'}>
        <img width={200} height={200} src={'./dead_end.png'} alt={'Error'} />
      </div>
      <div className={'error-header'}>
        You have made a wrong turn!
      </div>
      <div className={'error-txt'}>
        We will try to guide you back by sending you a notification in your Rewarded Play App.
        Click on the notification to find your way back on the road.
        If you don’t get a notification please use help/support option in the Rewarded Play App.
      </div>
    </div>
  )
}
export default Error