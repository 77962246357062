import React, { useCallback, useEffect, useState } from 'react';
import './wordle.scss';
import { Dialog, DialogContent, Snackbar } from "@mui/material";
import { wordleService } from "../../services/wordle";
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

type InputLetter = {
  letter: string | null;
  state: string | null;
}

type InputLetters = {
  letters: InputLetter[]
}

type UsedLetters = {
  [key: string]: string;
}
function WordleGame(props: {landmark_id: string, landmark_name: string}) {
  const firstRow = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
  const secondRow = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
  const thirdRow = ['enter', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '\u232B'];

  const [gameLevel, setGameLevel] = useState('');
  const [numberOfChances, setNumberOfChances] = useState(0);
  const [numberofRemainingTries, setNumberOfRemainingMoves] = useState(0);

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [inputWord, setInputWord] = useState<InputLetters>({letters: [
      {letter: null, state: null},
      {letter: null, state: null},
      {letter: null, state: null},
      {letter: null, state: null},
      {letter: null, state: null}
    ]
  });
  const [currentGuess, setCurrentGuess] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);
  const [allRows, setAllRows] = useState([]);
  const [usedLetters, setUsedLetters] = useState<UsedLetters>({});
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsgTxt, setErrorMsgTxt] = useState('');

  const onLetterClick = (letter: string) => {
    if(letter !== 'enter' && letter !== '\u232B') {
      if(inputWord.letters.some((el) => el.letter === null)) {
        let tmpArr = [...inputWord.letters];
        if(tmpArr[currentGuess]['letter'] !== null) {
          tmpArr[currentGuess + 1]['letter'] = letter;
          setInputWord({letters: tmpArr});
        } else {
          tmpArr[currentGuess]['letter'] = letter;
          setInputWord({letters: tmpArr});
        }
        if(currentGuess < 4) {
          setCurrentGuess(currentGuess + 1);
        }
      }
    }

    if(letter === '\u232B') {
      if(inputWord.letters.some((el) => el.letter !== null)) {
        let tmpArr = [...inputWord.letters];
        tmpArr[currentGuess]['letter'] = null;
        setInputWord({letters: tmpArr});
        if(currentGuess > 0) {
          setCurrentGuess(currentGuess - 1);
        }
      }
    }

    if(letter === 'enter') {
      const word = inputWord.letters.map((el) => el.letter).join('');
      if(currentRow !== (allRows.length - 1)) {
        setCurrentGuess(0);
        setInputWord({letters: [
            {letter: null, state: null},
            {letter: null, state: null},
            {letter: null, state: null},
            {letter: null, state: null},
            {letter: null, state: null}
          ]
        });
      }
      wordleService.submitWord(word, props.landmark_id).then((r) => {
        if(r.error) {
          setErrorMsgTxt(r.data.message);
          setShowErrorMsg(true);
          return;
        }
        if(r.rows) {
          setAllRows(r.rows);
        }
        if(r.remaining_moves) {
          setNumberOfRemainingMoves(r.remaining_moves);
        }
        if(r.currentRow) {
          if(currentRow !== (allRows.length - 1)) {
            setCurrentRow(r.currentRow);
          } else {
            // end of game, set current row out of bounds, so it colors last row correctly
            setCurrentRow(-1);
          }
        }
        if(r.used_letters) {
          setUsedLetters(r.used_letters);
        }
        if(r.status && r.status === 'failed') {
          setOpenFailedDialog(true);
        }
        if(r.status && r.status === 'success') {
          setOpenCongratulationsDialog(true);
        }
      })
    }
  }

  const getLetterClass = (state: string | null) => {
    if(state === 'wrong_placement') {
      return 'yellow';
    }
    if(state === 'no_placement') {
      return 'grey';
    }
    if(state === 'correct_placement') {
      return 'green';
    }
    return '';
  }

  const setupGame = useCallback(() => {
    wordleService.getGame(props.landmark_id).then((r) => {
      if(r && r.metadata) {
        if(r.metadata.difficulty) {
          setGameLevel(r.metadata.difficulty);
        }
        if(r.metadata.currentRow) {
          setCurrentRow(r.metadata.currentRow);
        }
        if(r.metadata.available_moves) {
          setNumberOfChances(r.metadata.available_moves);
        }
        if(r.metadata.available_moves) {
          setNumberOfChances(r.metadata.available_moves);
        }
        if(r.metadata.remaining_moves) {
          setNumberOfRemainingMoves(r.metadata.remaining_moves);
        }
        if(r.metadata.rows) {
          setAllRows(r.metadata.rows);
        }
        if(r.metadata.used_letters) {
          setUsedLetters(r.metadata.used_letters);
        }
        setInputWord(r.metadata.rows[r.metadata.currentRow]);
      }
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const handleCloseCongratulationsDialog = () => {
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog = () => {
    setOpenFailedDialog(false);
    window.location.reload();
  }

  const getUsedLettersClass = (l: string) => {
    if(usedLetters.hasOwnProperty(l)) {
      if(usedLetters[l] === 'no_placement') {
        return 'gray_letter';
      }
      if(usedLetters[l] === 'wrong_placement') {
        return 'yellow_letter';
      }
      if(usedLetters[l] === 'correct_placement') {
        return 'green_letter';
      }
    } else {
      return '';
    }
  }

  return (
    <div style={{minHeight: '100vh', background: '#444550'}}>

      <div style={{background: '#F8BD5D', paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'wordle-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'wordle-game-subheader'}>Guess the word game</div>
        <div className={'wordle-game-description'}>
          Guess the word to win the {props.landmark_name} Souvenir!
          You have limited tries to succeed but if you fail your next attempt will be easier.
        </div>

        <div style={{marginBottom: '12px', height: '315px', overflow: 'scroll'}}>
          {allRows.map((row: any, index) =>
            <div>
              {index === currentRow ?
                <div className={'wordle-row'}>
                  {inputWord.letters.map((l, index) => <div className={getLetterClass(l.state)} key={index}>{l.letter}</div>)}
                </div>
                :
                <div className={'wordle-row'}>
                  {row.letters.map((l: any, index: number) => <div className={getLetterClass(l.state)} key={index}>{l.letter}</div>)}
                </div>
              }
            </div>
          )}
        </div>

        <div className={'wordle-game-stats'}>
          <div>
            <div className={'wordle-game-lbl'}>Difficulty</div>
            <div className={'wordle-game-val'}>{gameLevel}</div>
          </div>
          <div>
            <div className={'wordle-game-lbl'}>Number of tries</div>
            <div className={'wordle-game-val'}>{numberOfChances}</div>
          </div>
          <div>
            <div className={'wordle-game-lbl'}>Tries remaining</div>
            <div className={'wordle-game-val'}>{numberofRemainingTries}</div>
          </div>
        </div>
      </div>

      <div className={'wordle-game-footer-container'}>
        <div className='wordle-game-keyboard-container'>

          <div style={{padding: "0 12px"}}>
            <div className='wordle-game-row'>
              {firstRow.map((l, index) =>
                <div key={index} className={'wordle-game-letter ' + getUsedLettersClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
            <div className='wordle-game-row'>
              {secondRow.map((l, index) =>
                <div key={index} className={'wordle-game-letter ' + getUsedLettersClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
            <div className='wordle-game-row'>
              {thirdRow.map((l, index) =>
                <div key={index} className={'wordle-game-letter ' + getUsedLettersClass(l)} onClick={() => onLetterClick(l)}>
                  {l}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name} closeDialog={handleCloseCongratulationsDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog} />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={showErrorMsg}
        autoHideDuration={3000}
        onClose={() => setShowErrorMsg(false)}
        message={errorMsgTxt}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

    </div>
  );
}

export default WordleGame