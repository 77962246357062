
import React from "react";
import "./notificationsAchievement.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotificationsAchievement(props: any) {
  const navigate = useNavigate();

  const navigateToAchievements = () => {
    if(props.closeDialog) {
      props.closeDialog();
    }
    navigate('/profile?tab=1');
  }

  return (
    <div className='notifications-achievement-container'>
      <div className='notifications-achievement-title'>
        You have earned a new achievement!
      </div>
      <div className='notifications-achievement-img'>
        <img height={118} alt={props.notification.metadata.name} src={props.notification.metadata.image_url} />
      </div>
      <div  className='notifications-achievement-description'>
        You have earned the "{props.notification.metadata.name}" achievement by {props.notification.metadata.description}.
      </div>
      <div className={'notifications-achievement-btn-container'}>
        <Button onClick={() => navigateToAchievements()} className='notifications-achievement-btn'>SEE ACHIEVEMENTS</Button>
      </div>
    </div>
  )
}
export default NotificationsAchievement