import React, { useCallback, useEffect, useState } from "react";
import "./notificationsSmartTrade.scss";
import { Button, CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { tradeService } from "../../services/trade";
import { notificationsService } from "../../services/notifications";
import { TradeItem } from "../../types/trades";

function NotificationsSmartTrade(props: any) {
  const [selectedSouvenir, setSelectedSouvenir] = useState('');
  const [itemsToReceive, setItemsToReceive] = useState<TradeItem[]>([]);
  const [itemsToGive, setItemsToGive] = useState<TradeItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationState, setNotificationState] = useState('');
  const [userToTradeWith, setUserToTradeWith] = useState('');

  const getNotificationsDetails = useCallback(() => {
    setIsLoading(true);
    notificationsService.getNotificationDetails(props.notification._id).then((r) => {
      if(r.trade_detail) {
        if(r.trade_detail.receive) {
          setItemsToReceive(r.trade_detail.receive);
        }
        if(r.trade_detail.trading) {
          setItemsToGive(r.trade_detail.trading);
        }
        if(r.trade_detail.state) {
          setNotificationState(r.trade_detail.state);
        }
        if(r.trade_detail.trading_user) {
          setUserToTradeWith(r.trade_detail.trading_user);
        }
      }
      setIsLoading(false);
    })
  }, [props.notification._id]);

  useEffect(() => {
    if(props.notification.require_trade_detail) {
      getNotificationsDetails();
    }
  }, [getNotificationsDetails, props.notification.require_trade_detail]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSouvenir(event.target.value as string);
  };

  const processTrade = (state: string) => {
    const t = {
      trade_id: props.notification.metadata.trade_id,
      status: state,
      landmark_id: selectedSouvenir
    }
    tradeService.completeTrade(t).then((r) => {
      if(!r.error) {
        props.markNotification(props.notification._id, r.message);
      } else {
        if(r.data.mark_notification_as_read) {
          props.markNotification(props.notification._id, r.data.message);
        }
      }
    })
  }

  const close = () => {
    props.markNotification(props.notification._id, null);
  }

  const closeAll = () => {
    props.markAllAsRead();
  }

  return (
    <div className='notifications-smart-trade-container'>
      {isLoading && <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress style={{'color': '#E81961'}} />
      </div>}
      {!isLoading &&
        <>
          <div className='notifications-smart-trade-title'>
            You have a smart trade offer from player {userToTradeWith}
          </div>
          <div className='notifications-smart-trade-subtitle'>
            Trade created: {props.notification.created_at}
          </div>

          <div className='notifications-smart-trade-items-container'>
            <div className='notifications-smart-trade-items-title'>You would get</div>
            {itemsToReceive.map((i: any) =>
              <div key={i._id} className='notifications-smart-trade-item'>
                Souvenir for {i.name} in {i.city_name}
              </div>
            )}
          </div>
          <div className='notifications-smart-trade-items-container'>
            <div className='notifications-smart-trade-items-title'>You would give</div>
            <FormControl fullWidth>
              <Select
                value={selectedSouvenir}
                onChange={handleChange}
                className={'notifications-smart-trade-li'}
              >
                {itemsToGive.map((i: any) =>
                  <MenuItem style={{height: '38px', minHeight: '38px'}} sx={{
                    "& .MuiTypography-root": {
                      color: '#000',
                      fontFamily: 'AntipastoRegular',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                    }
                  }} key={i._id} value={i._id}>{i.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          {notificationState === 'valid' &&
            <div className='notifications-smart-trade-btns-container'>
              <Button onClick={() => processTrade('rejected')} className='notifications-smart-trade-btn'>Reject</Button>
              <Button
                disabled={selectedSouvenir === ''}
                onClick={() => processTrade('accepted')}
                className={(selectedSouvenir === '') ? 'notifications-smart-trade-btn-dsbl' : 'notifications-smart-trade-btn'}
              >Accept</Button>
            </div>
          }

          {notificationState === 'expired' &&
            <>
              <div className={'notifications-smart-trade-txt'}>
                Sorry!  This trade has already been fulfilled by another player.  You can close all fulfilled trade notifications by clicking “CLOSE ALL” below.
              </div>
              <div className='notifications-smart-trade-btns-container'>
                <Button onClick={() => close()} className='notifications-smart-trade-btn'>Close</Button>
                <Button
                  onClick={() => closeAll()}
                  className={'notifications-smart-trade-btn'}
                >Close All</Button>
              </div>
            </>
          }

          {notificationState === 'invalid' &&
            <>
              <div className={'notifications-smart-trade-txt'}>
                You no longer have the duplicate this trade requires. You can close all notifications for trades you can't currently complete by tapping "Close all" below.
              </div>
              <div className='notifications-smart-trade-btns-container'>
                <Button onClick={() => close()} className='notifications-smart-trade-btn'>Close</Button>
                <Button
                  onClick={() => closeAll()}
                  className={'notifications-smart-trade-btn'}
                >Close All</Button>
              </div>
            </>
          }

        </>
      }
    </div>
  )
}
export default NotificationsSmartTrade