import React from "react";
import "./notificationsGlobalInfo.scss";

function NotificationsGlobalInfo(props: any) {

  return (
    <div className='notifications-global-info-container'>
      <div className='notifications-global-info-title'>
        {props.notification.metadata.title}
      </div>
      <div  className='notifications-global-info-description'>
        There has been a technical error that came to the attention of the dev team today.
        This allowed some people to get extra souvenirs. We didn't want anything to inhibit the integrity of the game so we decided to roll back and delete those souvenirs.
      </div>
      <div  className='notifications-global-info-description'>
        If you traded with someone who got one of those, the trade will be reversed too.
        You will get your original souvenir back, but you'll have to trade it again.
        For the next few hours we will restrict trading, mini-games and voucher delivery.
        Don't worry, we will fully make them up to you AND ensure you get a fair shot at the title here in RewardRush!
        Bottom line, this will ensure that nobody has an unfair advantage with winning the event.
      </div>
      <div  className='notifications-global-info-description'>
        We apologize for any inconvenience this causes. If, for any reason, you think that one of your souvenirs was deleted in error,
        please go to general chat and ask for Kitt. She will help to get your account made whole.
      </div>
    </div>
  )
}
export default NotificationsGlobalInfo