
import React from "react";
import "./notificationsTradeSuccess.scss";

function NotificationsTradeSuccess(props: any) {
  console.log(props, "props")
  return (
    <div className='notifications-trade-success-container'>
      <div className='notifications-trade-success-title '>Successful trade! You have received</div>
      {props.notification.metadata.receive.map((souv: any) => <div className='notifications-trade-success-items-container' key={souv._id}>
        <div className='notifications-trade-success-item-title'>{souv.city_name}: {souv.name} </div>
        <div>
          <img height={120} width={120} alt={'souvenir'} src={souv.souvenir_image_url}  />
        </div>
      </div> )}
    </div>
  )
}
export default NotificationsTradeSuccess