import React, { useCallback, useEffect, useState } from "react";
import './rewards.scss';
import Loading from "../../components/Loading/Loading";
import AppHeader from "../../components/AppHeader/AppHeader";
import { rewardsService } from "../../services/rewards";
import { Button, Pagination, Snackbar, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { userService } from "../../services/user";

type Reward = {
  achievement_name: string;
  text: string;
  gift_cards: GiftCard[]
}

type GiftCard = {
  dollar_amount: number;
  reference_id: string;
  link: string;
}

type RewardsObject = {
  is_rewarded: boolean;
  need_verification: boolean;
  awards: Reward[];
}

export function Rewards() {
  const [isLoading, setIsLoading] = useState(false);
  const [needVerification, setNeedVerification] = useState(false);
  const [haveReward, setHaveReward] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const navigate = useNavigate();
  const [currentReward, setCurrentReward] = useState<Reward>({
    text: '',
    achievement_name: '',
    gift_cards: []
  });
  const [page, setPage] = React.useState(1);
  const [rewards, setRewards] = useState<Reward[]>([]);


  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const getRewardsInfo = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      rewardsService.getRewardsInfo().then((r: RewardsObject) => {
        setIsLoading(false);
        if(r && r.is_rewarded) {
          setHaveReward(r.is_rewarded);
        }
        if(r && r.need_verification) {
          setNeedVerification(r.need_verification);
        }
        if(r && r.awards) {
          setRewards(r.awards);
          if(r.awards.length > 0) {
            setCurrentReward(r.awards[0]);
          }
        }
      })
    })
  }, [])

  useEffect(() => {
    getRewardsInfo();
  }, [getRewardsInfo]);

  const keepPlaying = () => {
    navigate('/home');
  }

  const redeemReward = (l: string) => {
    window.open(l);
  }

  const resendVerification = () => {
    userService.resendVerification().then((res) => {
      if(res && res.message) {
        setPopupMessage(res.message);
        setShowPopup(true);
      }
    })
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentReward(rewards[value - 1]);
  };

  return (
    <div style={{paddingTop: '50px'}}>
      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading &&
        <div>
          <AppHeader title={'Rewards'}/>
          <div className={'rewards-container'}>
            <div className={'rewards-container-header'}>
              <div style={{width: '300px', marginTop: '8px'}}>Redeem Rewards</div>
            </div>
            {needVerification &&
              <div>
                <div className={'rewards-main-header'}>Additional Verification Required</div>
                <div className={'rewards-info-section'}>
                  <div className={'rewards-info-txt'} style={{marginBottom: '20px'}}>
                    In order to ensure secure delivery of your reward,
                    we need to verify your identity by sending you a notification through your Rewarded Play App.
                  </div>
                  <div className={'rewards-info-txt'} style={{marginBottom: '52px'}}>
                    Please check your Rewarded Play App for the notification now.
                    Click the notification to be sent directly to your Reward Redemption page.
                  </div>
                  <div className={'rewards-btn-container'}>
                    <Button onClick={() => resendVerification()} className={'rewards-btn'}>Resend Verification</Button>
                  </div>
                </div>
              </div>
            }
            {(!needVerification && !haveReward) &&
              <div>
                <div className={'rewards-main-header'}>Sorry!</div>
                <div className={'rewards-info-section'}>
                  <div className={'rewards-info-txt'} style={{marginBottom: '20px'}}>
                    <div className={'rewards-info-txt-header'}>You have not earned any rewards yet</div>
                    <div className={'rewards-info-txt-subheader'}>Keep playing and earn achievements for more chances to win</div>
                  </div>
                  <div className={'rewards-btn-container'}>
                    <Button onClick={() => keepPlaying()} className={'rewards-btn'}>Keep Playing</Button>
                  </div>
                </div>
              </div>
            }
            {(!needVerification && haveReward) &&
              <div>
                <div className={'rewards-main-header'}>Congratulations!</div>
                <div className={'rewards-info-section'} style={{marginBottom: '12px'}}>
                  <Stack alignItems="center" style={{marginBottom: '16px'}}>
                    <Pagination sx={{button:{color: '#000'},'& .Mui-selected': {
                        backgroundColor: '#F3C42B'
                      },}} page={page} onChange={handlePageChange} count={rewards.length} size="small" />
                  </Stack>
                  <div className={'rewards-info-txt'} style={{marginBottom: '20px'}}>
                    <div className={'rewards-info-txt-header'}>{currentReward.text}</div>
                  </div>
                  <div className={'rewards-table'}>
                    <table style={{borderCollapse: "collapse", width: '100%'}}>
                      <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Ref. ID</th>
                        <th>Redeem</th>
                      </tr>
                      </thead>
                      <tbody>
                      {currentReward.gift_cards.map((gc) =>
                        <tr key={gc.reference_id}>
                          <td>${gc.dollar_amount}</td>
                          <td>{gc.reference_id}</td>
                          <td><Button onClick={() => redeemReward(gc.link)} className={'rewards-btn'}>Redeem</Button></td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={'rewards-info-section'}>
                  <div className={'rewards-info-txt'} style={{marginBottom: '20px'}}>
                    <div className={'rewards-info-txt-header'}>Instructions</div>
                    <div className={'rewards-info-bold-txt'} style={{textAlign: 'left', marginBottom: '12px'}}>
                      Only use this gift card's code on Google Play. Any other request for the code may be a scam. Visit&nbsp;
                      <a href={'https://play.google.com/giftcardscam'}>play.google.com/giftcardscam</a>
                      .
                    </div>
                    <div className={'rewards-info-bold-txt'} style={{textAlign: 'left', marginBottom: '12px'}}>
                      If you have any problems or questions, please visit <a href={'https://help.tangocard.com'}>https://help.tangocard.com</a>.
                      To help us better assist you, we'll ask for your reference ID that you can copy from the table above.
                    </div>
                    <div className={'rewards-info-bold-txt'} style={{textAlign: 'left', marginBottom: '12px'}}>
                      See <a href={'https://play.google.com/us-card-terms'}>https://play.google.com/us-card-terms</a> full terms.
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }

      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}