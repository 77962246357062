import React, { useCallback, useEffect, useState } from 'react';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import '../HangmanGame/hangmanGame.scss';
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { inventoryService } from "../../services/inventory";

function FailedDialog(props: any) {

  const [isLoading, setIsLoading] = useState(false);
  const [vouchers, setVouchers] = useState(0);
  const navigate = useNavigate();

  const getInventory = useCallback(() => {
    setIsLoading(true);
    inventoryService.getInventory().then((r: any) => {
      if (r && !r.error) {
        if (r.vouchers) {
          let tmpVouchers = 0;
          if(r.vouchers.bonus_vouchers) {
            tmpVouchers += r.vouchers.bonus_vouchers;
          }
          if(r.vouchers.standard_vouchers) {
            tmpVouchers += r.vouchers.standard_vouchers;
          }
          setVouchers(tmpVouchers);
        }
      }
      setIsLoading(false);
    })
  }, []);

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  const navigateToBooking = () => {
    navigate(`/booking`);
  }

  const navigateToInventory = () => {
    navigate(`/inventory`);
  }

  return (
    <div>
      {isLoading && <div style={{textAlign: 'center'}}>
        <CircularProgress style={{'color': '#E81961'}} />
      </div>}
      {!isLoading &&
        <div>
          <div  onClick={() => props.closeDialog()} style={{position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
            <HighlightOffIcon style={{color: '#CCCCCC'}} width={'28px'} height={'28px'} />
          </div>
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '12px'}}>
            <img src={'./lost_mini_game.svg'} alt={'lost mini game'} height={116} width={116} />
          </div>
          <div className={'hangman-game-dialog-failed-header'}>Better Luck Next Time!</div>
          <div className={'hangman-game-dialog-failed-txt'}>You were unable to win the {props.landmark_name} mini-game but don’t worry, the next time you play it will be easier.</div>
          {vouchers > 0 && <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button className={'hangman-game-dialog-failed-btn'} onClick={() => navigateToBooking()}>Redeem Another Voucher</Button>
            </div>
          </div>
          }
          {vouchers === 0 && <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button className={'hangman-game-dialog-failed-btn'} onClick={() => navigateToInventory()}>Want more vouchers?</Button>
            </div>
          </div>
          }
        </div>
      }
    </div>
  );
}

export default FailedDialog