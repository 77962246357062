import React from "react";
import AppHeader from "../AppHeader/AppHeader";
import Error from "../Error/Error";


function ErrorWithHeader() {

  return (
    <div style={{paddingTop: '50px'}}>
      <AppHeader title={'Error'} />
      <Error />
    </div>
  )
}
export default ErrorWithHeader