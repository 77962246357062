import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button, Dialog,
  DialogContent, DialogProps,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { userService } from "../../services/user";
import './profile.scss';
import Loading from "../../components/Loading/Loading";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useSearchParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type UserProfileSettings = {
  display_name: string;
  enable_chat: boolean;
  notifications_per_day: number;
}

type UserProfileStatistics = {
  achievement_score: number;
  duplicate_souvenirs: number;
  duplicate_tickets: number;
  minigame_attempts: number;
  minigames_won: number;
  tours: number;
  unique_souvenirs: number;
  unique_tickets: number;
}

type UserProfileAchievementLevel = {
  name: string;
  description: string;
}

type UserProfileAchievement = {
  _id: string;
  name: string;
  description: string;
  image_url: string;
  levels: UserProfileAchievementLevel[];
  achievement_obtained: boolean;
  current_level: null | number;
}

type UserProfile = {
  settings: UserProfileSettings;
  achievements: UserProfileAchievement[];
  statistics: UserProfileStatistics;
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='workshop-tab-content' sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function Profile() {
  const [displayName, setDisplayName] = useState("");
  const [notificationsPerDay, setNotificationsPerDay] = useState(2);
  const [isLoading, setisLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [chatEnabled, setChatEnabled] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<UserProfileStatistics>({
    achievement_score: 0,
    duplicate_souvenirs:  0,
    duplicate_tickets:  0,
    minigame_attempts:  0,
    minigames_won:  0,
    tours:  0,
    unique_souvenirs:  0,
    unique_tickets:  0
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [achievements, setAchievements] = useState<UserProfileAchievement[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState<UserProfileAchievement>({
    _id: '',
    name: '',
    image_url: '',
    levels: [],
    achievement_obtained: false,
    description: '',
    current_level: null
  });


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams(params => {
      params.set("tab", JSON.parse(newValue.toString()));
      return params;
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatEnabled(event.target.checked);
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = (event: React.FormEvent) => {
    setisLoading(true);
    sleep(1000).then(() => {
      event.preventDefault();
      userService.updateSettings({
        notifications_per_day: notificationsPerDay,
        display_name: displayName,
        enable_chat: chatEnabled
      }).then((r) => {
        if(r.error) {
          setErrorTxt(r.data.message);
          setIsError(true);
          setisLoading(false);
        } else {
          setErrorTxt("");
          setIsError(false);
          getUser();
          setisLoading(false);
          setSnackBarOpen(true);
        }
      }).catch((e) => {
        setisLoading(false);
      })
    })
  }

  const getUser = useCallback(() => {
    setisLoading(true);
    sleep(1000).then(() => {
      userService.getUser().then((r: UserProfile) => {
        if (r && r.settings) {
          setNotificationsPerDay(r.settings.notifications_per_day);
          setDisplayName(r.settings.display_name);
          setChatEnabled(r.settings.enable_chat);
        }
        if(r && r.statistics) {
          setStatistics(r.statistics);
        }
        if(r && r.achievements) {
          setAchievements(r.achievements);
        }
        if(searchParams && searchParams.get("tab")) {
          const t = searchParams.get("tab");
          if(t) {
            setTabValue(parseInt(t));
          }
        }
        setisLoading(false);
      })

    })
  }, [searchParams])

  useEffect(() => {
    getUser();
  }, [getUser]);

  const selectAchievement = (achievement: UserProfileAchievement) => {
    setSelectedAchievement(achievement);
    setOpenDialog(true);
  }

  const closeDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setSelectedAchievement({
      _id: '',
      name: '',
      image_url: '',
      levels: [],
      achievement_obtained: false,
      description: '',
      current_level: null
    });
    setOpenDialog(false);
  }

  return (
    <div style={{paddingTop: '50px'}}>
      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading &&
        <div>
          <AppHeader title={'Profile'}/>
          <div>
            <div style={{minHeight: '100vh'}}>
              <Box sx={{ width: '100%' }} >
                <Box>
                  <Tabs
                    sx={{
                      ".MuiButtonBase-root" : {
                        background: `#FBF8EE !important`,
                        color: '#B2B2BA !important'
                      },
                      ".Mui-selected": {
                        background: `#FBF8EE !important`,
                        color: '#5F607B !important',
                      }
                    }}
                    TabIndicatorProps={{style: {background: "#5F607B"}}}
                    className='workshop-tabs-container'
                    value={tabValue}
                    onChange={handleChange}
                    variant={'fullWidth'}
                  >
                    <Tab className="workshop-tabs-lbl" label="Settings"  />
                    <Tab className="workshop-tabs-lbl" label="Achievements"  />
                    <Tab className="workshop-tabs-lbl" label="Statistics"  />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                  <div className='profile-tab-container'>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                      <div style={{textAlign: 'left', padding: '16px', marginBottom: '24px'}}>
                        <div className='profile-form-lbl'>Display Name *</div>
                        <TextField
                          error={isError}
                          helperText={errorTxt}
                          onChange={e => setDisplayName(e.target.value)}
                          required
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={displayName}
                          sx={{
                            '.MuiInputBase-input': {
                              fontFamily: "TypoHoopRegular, serif",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              padding: '12px 10px',
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                              border: 'none'
                            },
                          }}
                          style={{
                            borderRadius: "25px",
                            border: "2px solid #AAA",
                            background: "#FFF",
                            height: '48px',
                            marginBottom: "24px"
                          }}
                        />
                        <div className='profile-form-lbl'>Max Voucher Notifications/Day</div>
                        <Select
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                '&.MuiPaper-root': {
                                  borderRadius: '25px',
                                  top: "170px !important",
                                  border: "2px solid #AAA",
                                  minWidth: '330px',
                                },
                              }
                            }
                          }}
                          fullWidth
                          value={notificationsPerDay}
                          onChange={e => setNotificationsPerDay(e.target.value as number)}
                          style={{background: "#FFF", borderRadius: "25px",
                            border: "2px solid #AAA", height: '48px',  fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            marginBottom: "20px",
                            fontStyle: "normal"}}
                        >
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={0}>Opt out</MenuItem>
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={1}>1</MenuItem>
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={2}>2</MenuItem>
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={3}>3</MenuItem>
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={4}>4</MenuItem>
                          <MenuItem sx={{
                            fontFamily: "TypoHoopRegular, serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "18px",
                            borderBottom: '1px solid #EEEEEE',
                            paddingLeft: '10px'
                          }} value={5}>5</MenuItem>
                        </Select>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div className='profile-form-lbl' style={{paddingTop: '8px'}}>Enable Chat</div>
                          <div>
                            <Switch checked={chatEnabled} onChange={handleCheckboxChange} />
                          </div>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button  variant={'contained'} type='submit' className='profile-btn'>Save</Button>
                      </div>
                    </form>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <div>
                    <div className='profile-achievements-txt'>
                      Achievements are earned by completing certain goals or playing a certain way.
                      They stay with you across tours and can gain you benefits, advantages, or rewards.
                    </div>

                    <div className={'profile-achievements-wrapper'}>
                      {achievements.map((ach) =>
                        <div onClick={() => selectAchievement(ach)} key={ach._id} className='profile-achievements-container'>
                          <div style={ach.achievement_obtained ? {} : {filter: 'grayscale(1)'}}>
                            <img style={{marginTop: '2px'}} width={'75px'} src={ach.image_url} alt={ach.name}/>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  <div>
                    <div className='profile-stats-row'>
                      <div>Unique Souvenirs:</div>
                      <div>{statistics.unique_souvenirs}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div>Unique Tickets:</div>
                      <div>{statistics.unique_tickets}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div># of Duplicate Souvenirs:</div>
                      <div>{statistics.duplicate_souvenirs}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div># of Duplicate Tickets:</div>
                      <div>{statistics.duplicate_tickets}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div>Achievement Score:</div>
                      <div>{statistics.achievement_score}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div>Mini-game Attempts:</div>
                      <div>{statistics.minigame_attempts}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div>Mini-games Won:</div>
                      <div>{statistics.minigames_won}</div>
                    </div>
                    <div className='profile-stats-row'>
                      <div># of Tours:</div>
                      <div>{statistics.tours}</div>
                    </div>
                  </div>

                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      }
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message="Profile updated successfully!"
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

      <Dialog
        open={openDialog}
        onClose={closeDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "8px"
            },
          },
        }}
      >
        <DialogContent>
          <div>
            <div  onClick={() => closeDialog({}, 'escapeKeyDown')} style={{color: "#000", position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
              <HighlightOffIcon width={'28px'} height={'28px'} />
            </div>
            <div style={{textAlign: 'center'}}>
              <img width={150} src={selectedAchievement.image_url} alt={selectedAchievement.name} />
            </div>
            <div className='profile-dialog-ach-name'>
              {selectedAchievement.name}
            </div>
            <div className={'profile-dialog-ach-description'}>
              {selectedAchievement.description}
            </div>
            <div>
              {selectedAchievement.levels && selectedAchievement.levels.map((l, index) =>
                <div key={index}>
                  <div className={(selectedAchievement.current_level !== null && index === selectedAchievement.current_level - 1) ? 'profile-dialog-ach-level-current' : 'profile-dialog-ach-level'}>
                    <span>{l.name}:</span> <span>{l.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}