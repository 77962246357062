export const notificationsService = {
  getNotifications: async function (popup: boolean) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/notifications?rr_token=${lsToken}&unread=true&popup_only=${popup}`)
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  markNotificationAsRead: async function (payload: any) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/notification/read?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          window.location.href = window.location.origin + '/login';
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  markGlobalNotificationAsRead: async function () {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/user/viewed_completion?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          window.location.href = window.location.origin + '/login';
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  getNotificationDetails: async function (notification_id: string) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/notification/trade_detail?rr_token=${lsToken}&notification_id=${notification_id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  markAllAsRead: async function () {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api//v2/notification/trade_notification_cleanup?rr_token=${lsToken}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
}